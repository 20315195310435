<template lang="">
  <div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Zeige</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>Einträge</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter"
              class="d-inline-block mr-1"
              placeholder="Suche..."
              type="search"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        ref="refListTable"
        striped
        hover
        :items="customerEntries"
        :fields="fields"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
        @row-clicked="onRowClicked"
      >
        <template #cell(firstName)="data">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </template>

        <template #cell(orders.length)="data">
          <b-badge variant="success">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Einträge</span
          >
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="getTotalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormCheckbox,
  BButtonToolbar,
  BButtonGroup,
  BModal,
  BTable,
  BFormInput,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import tableHelper from '../Table+Helper'

export default {
  setup() {
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()
    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },

  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormCheckbox,
    BButtonToolbar,
    BButtonGroup,
    BModal,
    BTable,
    BFormInput,
    BBadge,
    BPagination,
    vSelect,
  },
  props: {
    customerEntries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      whatami: '',
      fields: [
        {
          key: 'id',
          label: 'KDNR',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'Kunde',
          sortable: true,
        },
        {
          key: 'organizationName',
          label: 'Gewerbe',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-Mail',
          sortable: true,
        },

        {
          key: 'orders.length',
          label: 'Buchungen',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    getTotalRows() {
      return this.customerEntries.length
    },
  },
  updated() {
    // Set the initial number of items
    this.totalRows = this.getTotalRows
  },
  methods: {
    onRowClicked(item) {
      this.$emit('customerSelected', item)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}
</style>
