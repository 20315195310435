<template lang="">
  <div>
    <div class="pb-1">
      <h5>Rechnungsanschrift</h5>
    </div>
    <!-- <b-row class="">
      <b-col
        sm="12"
        md="12"
        lg="6"
        xl="4"
      >
        <b-form-group
          label="Rechnungsadresse"
          label-for="v-billingadress"
        >
          <v-select
            id="v-billingdress"
            ref="billingDiffers"
            v-model="booking.customer.billingAddressDiffers.value"
            :options="billingadressItems"
            :clearable="false"
            label="text"
          />
        </b-form-group>
      </b-col>
    </b-row> -->

    <b-row class="">
      <b-col sm="9" md="9" lg="10" xl="6">
        <b-form-group label="Strasse" label-for="">
          <validation-provider
            #default="{ errors }"
            name="Strasse"
            :rules="
              booking.customer.billingAddressDiffers.value === 'true'
                ? 'required'
                : ''
            "
          >
            <b-form-input
              v-model="booking.customer.billingStreet"
              :state="errors.length > 0 ? false : null"
              placeholder="Waynestrasse"
              :disabled="
                booking.customer.billingAddressDiffers.value === 'false'
              "
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col sm="3" md="3" lg="2" xl="2">
        <b-form-group label="Hausnummer" label-for="">
          <validation-provider
            #default="{ errors }"
            name="Hausnummer"
            :rules="
              booking.customer.billingAddressDiffers.value === 'true'
                ? 'required'
                : ''
            "
          >
            <b-form-input
              v-model="booking.customer.billingStreetNumber"
              :state="errors.length > 0 ? false : null"
              placeholder="1"
              :disabled="
                booking.customer.billingAddressDiffers.value === 'false'
              "
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="">
      <b-col sm="3" md="3" lg="2" xl="2">
        <b-form-group label="PLZ" label-for="">
          <validation-provider
            #default="{ errors }"
            name="PLZ"
            :rules="
              booking.customer.billingAddressDiffers.value === 'true'
                ? 'required|digits:5'
                : ''
            "
          >
            <b-form-input
              v-model="booking.customer.billingZipCode"
              :state="errors.length > 0 ? false : null"
              placeholder="33104"
              :disabled="
                booking.customer.billingAddressDiffers.value === 'false'
              "
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col sm="9" md="9" lg="10" xl="6">
        <b-form-group label="Stadt" label-for="">
          <validation-provider
            #default="{ errors }"
            name="Stadt"
            :rules="
              booking.customer.billingAddressDiffers.value === 'true'
                ? 'required'
                : ''
            "
          >
            <b-form-input
              v-model="booking.customer.billingCity"
              :state="errors.length > 0 ? false : null"
              placeholder="Paderborn"
              :disabled="
                booking.customer.billingAddressDiffers.value === 'false'
              "
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col sm="9" md="9" lg="6" xl="6">
        <b-form-group label="Land" label-for="">
          <validation-provider
            #default="{ errors }"
            name="Land"
            :rules="
              booking.customer.billingAddressDiffers.value === 'true'
                ? 'required'
                : ''
            "
          >
            <v-select
              id="v-country"
              v-model="booking.customer.billingCountry"
              :clearable="false"
              :options="getCountryOptions()"
              :disabled="
                booking.customer.billingAddressDiffers.value === 'false'
              "
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="pb-1 pt-1">
      <h5>Bezahlinformationen</h5>
    </div>

    <!-- <b-row class="pb-1">
            <b-col sm="12" md="6" lg="3" xl="3">
              <b-form-group label="Bezahlart" label-for="v-paymenttype">
                <v-select
                  id="v-paymenttype"
                  v-model="selectedPaymentType"
                  :options="paymentTypeItems"
                  :clearable="false"
                  label="text"
                />
              </b-form-group>
            </b-col>
          </b-row> -->

    <b-row class="">
      <b-col sm="12" md="12" lg="6" xl="5">
        <b-form-group label="Bank" label-for="v-bank">
          <validation-provider
            #default="{ errors }"
            name="Bank"
            rules="required"
          >
            <b-form-input
              v-model="booking.customer.bankName"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="pb-1">
      <b-col sm="12" md="12" lg="7" xl="5">
        <b-form-group label="IBAN" label-for="v-iban">
          <validation-provider
            #default="{ errors }"
            name="IBAN"
            rules="required"
          >
            <b-form-input
              v-model="booking.customer.bankIBAN"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="12" lg="5" xl="5">
        <b-form-group label="BIC" label-for="v-bic">
          <validation-provider
            #default="{ errors }"
            name="BIC"
            rules="required"
          >
            <b-form-input
              v-model="booking.customer.bankBIC"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import helper from '@/helper/helper'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BCard,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  setup() {
    const { getFormattedDate, getCountryOptions } = helper()

    return { getFormattedDate, getCountryOptions }
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BCard,
    BCardText,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: ['booking'],
}
</script>
<style lang=""></style>
