<template>
  <div>
    <h3>Booking</h3>
    <!-- <p>{{ booking }}</p> -->
    <!--     
    <h3>ShopUploads</h3>
    <p>{{ booking.customer.shop_uploads }}</p>
    <h3>Attachments</h3>
    <p>{{ booking.customer.attachments }}</p> -->
    Neue Datein hochladen. Sollten ShopUploads vorhanden sein, werden diese
    übernommen.
    <br /><br />
    <div v-if="booking.customer.shop_uploads">
      <h4 class="mb-1 mt-2">Shop Uploads anpassen</h4>

      <div
        v-for="(attachment, attachmentIndex) in booking.customer.attachments"
        :key="attachmentIndex"
      >
        <div v-for="(file, fileIndex) in attachment.files" :key="fileIndex">
          <p>{{ file.name }}</p>
          <b-row class="pb-1">
            <b-col md="4">
              <b-form-select
                v-model="attachment.attachment_type"
                :options="formattedUplAttachmentOptions"
              >
              </b-form-select>
            </b-col>
            <b-col md="6">
              <b-form-input
                v-model="file.note"
                autofocus
                trim
                placeholder="Notiz hinzufügen..."
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div>
      <h4 class="mb-1 mt-1">Neue Dateien hochladen</h4>
      <div v-for="attachment in booking.uplAttachment" :key="attachment.id">
        <div class="pb-1">
          <h5>{{ attachment.type }}</h5>
        </div>
        <b-row class="pb-1">
          <b-col md="6">
            <b-form-file
              v-model="attachment.files"
              placeholder="Dateien auswählen"
              drop-placeholder="Dateien hier rein ziehen..."
              multiple
            />
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="attachment.note"
              autofocus
              trim
              placeholder="Notiz hinzufügen..."
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BFormFile, BFormInput, BFormSelect, BRow } from 'bootstrap-vue'

export default {
  components: { BRow, BCol, BFormInput, BFormFile, BFormSelect },
  props: ['booking'],
  data() {
    return {
      attachmentOptions: this.booking.uplAttachment.map(a => ({
        value: a.id,
        text: a.type,
      })),
    }
  },
  computed: {
    attachmentTypes() {
      return this.$store.getters.getAttachmentsTypes
    },
    formattedUplAttachmentOptions() {
      return this.booking.uplAttachment.map(attachment => ({
        value: attachment.id, // Dies entspricht dem 'value'-Attribut der Option
        text: attachment.type, // Dies entspricht dem anzuzeigenden Text der Option
      }))
    },
  },
  mounted() {
    this.attachmentTypes.forEach(attachmentType => {
      const uploadAttachment = { id: null, type: '', files: [], note: '' }

      uploadAttachment.id = attachmentType.id
      uploadAttachment.type = attachmentType.attachmentType
      uploadAttachment.files = []
      uploadAttachment.note = ''

      this.booking.uplAttachment.push(uploadAttachment)
    })
  },
  methods: {},
}
</script>
<style lang=""></style>
