console.log("🚀 ~ file: Movein.vue:1 ~ formSubmitted:", formSubmitted)
<template>
  <div>
    <!-- Route: {{ parentRoute }} -->
    <validation-observer ref="simpleRules">
      <form-wizard
        ref="wizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="vertical"
        :finish-button-text="
          parentRoute ? 'Buchung durchführen' : 'Vertragsvorschau'
        "
        back-button-text="Zurück"
        next-button-text="Weiter"
        class="wizard-vertical mb-3"
        @on-complete="sendFormAction"
      >
        <!-- Select Customer Details -->
        <tab-content
          title="Kundendetails"
          :before-change="() => beforeTabSwitch(1)"
        >
          <move-in-customer-details
            :booking="booking"
            :inquiry-id="inquiryId"
            :parent-route="parentRoute"
            @updateParentRoute="updateParentRouteMethod"
          />
        </tab-content>

        <!-- {{ booking.customer.billingAddressDiffers.orderItem }} -->
        <!-- payment info tab -->
        <tab-content title="Zahlung">
          <move-in-payment-details :booking="booking" />
        </tab-content>
        <!-- Select Storages TAB -->
        <tab-content v-if="parentRoute !== 'offer'" title="Lager">
          <move-in-storage-selection
            :booking="booking"
            :inquiry="inquiry"
            :parent-route="parentRoute"
          />
        </tab-content>

        <!-- Select Attachment TAB -->
        <tab-content title="Dateien">
          <!-- {{ uplAttachment }} -->
          <move-in-attachment-selection ref="myChild" :booking="booking" />
        </tab-content>

        <!-- Make Booking TAB -->
        <tab-content title="Buchung">
          <!-- {{ order }} -->
          <move-in-make-booking
            :booking="booking"
            :parent-route="parentRoute"
          />
        </tab-content>
      </form-wizard>
    </validation-observer>

    <b-modal
      id="modal-booking"
      centered
      ok-only
      ok-title="Accept"
      :hide-footer="true"
      :hide-header="true"
      :hide-header-close="true"
      size="sm"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div class="d-flex justify-content-center mt-1 mb-1">
        <b-spinner label="Loading..." />
      </div>
      <div class="d-flex justify-content-center mb-1">
        {{ spinnerProgressLabel }}
      </div>
    </b-modal>

    <b-modal
      id="modal-booking-danger"
      ok-only
      ok-variant="danger"
      ok-title="Ok"
      modal-class="modal-danger"
      centered
      title="Upps, Fehler :("
    >
      <b-card-text>
        Im Buchungsprozess ist ein Fehler aufgetreten. Bitte versuchen Sie es
        erneut.
      </b-card-text>

      <b-button v-b-modal.modal-booking-errorcode variant="danger">
        Fehlercode anzeigen!
      </b-button>
    </b-modal>

    <b-modal
      id="modal-booking-errorcode"
      title="Fehlercode"
      scrollable
      size="lg"
      ok-only
      ok-variant="danger"
      ok-title="Ok"
      modal-class="modal-danger"
      centered
    >
      <b-card-text class="my-2">
        {{ errorcode }}
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-preview"
      scrollable
      title="Vertragsvorschau"
      cancel-title="Abbrechen"
      ok-variant="success"
      ok-title="Buchung durchführen"
      cancel-variant="outline-secondary"
      modal-class="modal-success"
      size="xl"
      @ok="formSubmitted"
    >
      <b-tabs>
        <b-tab
          v-for="(storage, index) in this.offer.storages"
          :key="index"
          :title="storage.storageIdent"
        >
          <b-card-text>
            <preview-contract-view
              :offer="offer"
              :pdf-storage-print-number="index"
              @contractPDFUpdate="updateContractArray"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emailjs from '@emailjs/browser'
import { required } from '@validations'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import { German } from 'flatpickr/dist/l10n/de'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vueflatpickr from 'vue-flatpickr-component'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

import {
  BButton,
  BCardText,
  BModal,
  BSpinner,
  BTab,
  BTabs,
  VBModal,
} from 'bootstrap-vue'

import emailStoragesBody from '@/helper/emailStoragesBody'
import helper from '@/helper/helper'
import sendEmail from '@/service/emailService'
import Offer from '@/store/offer/Offer'
import VueHtml2pdf from 'vue-html2pdf'
import bookingSummery from './BookingSummery.vue'
import ContractLagermeister from './ContractLagermeister.vue'
import MoveInAttachmentSelection from './MoveInAttachmentSelection.vue'
import MoveInCustomerDetails from './MoveInCustomerDetails.vue'
import MoveInMakeBooking from './MoveInMakeBooking.vue'
import MoveInPaymentDetails from './MoveInPaymentDetails.vue'
import MoveInStorageSelection from './MoveInStorageSelection.vue'
import PreviewContractView from './PreviewContractView.vue'

flatpickr.localize(German)

export default {
  setup() {
    const { getFormattedDate, getCountryOptions, getSalutionOptions } = helper()

    return { getFormattedDate, getCountryOptions, getSalutionOptions }
  },

  components: {
    BTabs,
    BCardText,
    BTab,
    MoveInCustomerDetails,
    MoveInPaymentDetails,
    MoveInStorageSelection,
    MoveInAttachmentSelection,
    MoveInMakeBooking,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BButton,
    vSelect,
    BSpinner,
    flatpickr: Vueflatpickr,
    BModal,
    emailjs,
    bookingSummery,
    VueHtml2pdf,
    Offer,
    ToastificationContent,
    ContractLagermeister,
    PreviewContractView,
    emailStoragesBody,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: ['inquiryId'],

  data() {
    return {
      parentRoute: this.$route.params.parentRoute,
      offer: Offer,
      booking: {
        customer: this.$route.params.booking
          ? this.$route.params.booking
          : { billingAddressDiffers: false },
        order: {
          orderDate: null,
          orderDetails: null,
          order_items: [],
          customer: null,
          created_by: null,
          updated_by: null,
          customerSignature: null,
        },
        uplAttachment: [],
        selectedStorages: this.$route.params.storages
          ? this.$route.params.storages
          : [],
        isExistingCustomer: false,
        bookingDate: null,
        invoiceNumber: null,
        customerSignatureImage: null,
        landlordSignatureImage: null,
        contractArray: [],
        generateContract: true,
      },
      inquiry: null,
      pdfBookingFile: null,
      pdfStoragePrintNumber: 0,
      spinnerProgressLabel: '',
      required,
      // countryOptions: ['Deutschland', 'Schweiz', 'Österreich'],
      selectedPaymentType: { orderItem: 'lastschrift', text: 'Lastschrift' },
      selectedStorageLocation: 'Standort auswählen...',
      billingadressItems: [
        { orderItem: 'false', text: 'Identisch mit Kundenadresse' },
        { orderItem: 'true', text: 'NICHT Identisch mit Kundenadresse' },
      ],
      paymentTypeItems: [
        { orderItem: 'lastschrift', text: 'Lastschrift' },
        { orderItem: 'kreditkarte', text: 'Kreditkarte' },
      ],
      errorcode: '',
      emailAttachments: [],
    }
  },
  computed: {
    //   parentName() {
    //   return this.$parent.$options.name
    // }
  },

  methods: {
    updateParentRouteMethod(newRoute) {
      this.parentRoute = newRoute
    },

    sendFormAction() {
      console.log(
        '🚀 ~ file: Movein.vue:307 ~ sendFormAction ~ this.parentRoute:',
        this.parentRoute
      )
      if (
        /* eslint-disable operator-linebreak */
        this.parentRoute === 'offer' ||
        this.parentRoute === 'addExistingOrder'
      ) {
        this.formSubmitted()
      } else {
        this.showBookingPreview()
      }
    },

    updateContractArray(pdf) {
      console.log('updateContractArray')
      this.booking.contractArray.push(pdf)
      console.log(this.booking.contractArray)
    },

    offerMapping() {
      this.offer.firstName = this.booking.customer.firstName
      this.offer.lastName = this.booking.customer.lastName
      this.offer.email = this.booking.customer.email
      this.offer.salutation = this.booking.customer.salutation
      this.offer.street = this.booking.customer.street
      this.offer.streetNumber = this.booking.customer.streetNumber
      this.offer.zipCode = this.booking.customer.zipCode
      this.offer.city = this.booking.customer.city
      this.offer.phoneNumber = this.booking.customer.phoneNumber
      this.offer.company = this.booking.customer.company
      this.offer.offerDate = Date.now()
      this.offer.storages = this.booking.selectedStorages
      this.offer.inquiry = this.booking.customer.id
      this.offer.customerSignatureImage = this.booking.customerSignatureImage
      this.offer.landlordSignatureImage = this.booking.landlordSignatureImage
      this.offer.isActive = true
    },

    showBookingPreview() {
      // empty this.booking.contractArray
      this.booking.contractArray = []

      this.offerMapping()
      this.$bvModal.show('modal-preview')
    },

    // ############################# PDF #############################
    generateBookingPDF() {
      this.offerMapping()
      this.$refs.html2Pdf.generatePdf()
    },

    generateBookingPDFButton() {
      // lop throug selectedStorages and create a pdf for each storage

      this.offerMapping()
      this.$refs.html2Pdf.generatePdf()

      this.booking.storages.forEach((storage, i) => {
        this.pdfStoragePrintNumber = i
        this.$refs.html2Pdf.generatePdf()
      })
    },

    saveContractPDF(pdf) {
      // alert('Dialog OK')

      const formData = new FormData()
      formData.append('files', pdf)

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.$store
        .dispatch('addFile', formData, config)
        .then(respond => {
          const attachmentWithFiles = {
            note: 'Vertragsunterlagen',
            customer: respond.id,
            attachment_type: 3,
            created_by: 'EK',
            updated_by: 'EK',
            files: respond.map(a => a.id),
          }

          this.$store
            .dispatch('addAttachment', attachmentWithFiles)
            .then(() => {
              // this.$router.go()
            })
        })
        .catch(() => {})
    },

    beforeTabSwitch() {
      return this.$refs.simpleRules.validate().then(success => {
        if (success) {
          return true
        }
        return true
      })
    },

    // ############################# Booking Form  #############################
    formSubmitted() {
      console.log('🚀 ~ file: Movein.vue:420 ~ formSubmitted:')
      this.spinnerProgressLabel = 'Fange mit der Magie an...'
      this.$bvModal.show('modal-booking')

      //* * OrderItems Array */
      const resultOrderItems = []

      // eslint-disable-next-line operator-linebreak
      this.booking.isExistingCustomer =
        this.$store.getters.customerExistByEmail(this.booking.customer.email)
      console.log(
        '🚀 ~ file: Movein.vue:429 ~ formSubmitted ~ this.booking:',
        this.booking
      )

      //* * Create the order_items */
      // eslint-disable-next-line no-plusplus
      for (
        let index = 0;
        index < this.booking.selectedStorages.length;
        index += 1
      ) {
        this.spinnerProgressLabel = `Erstelle Position ${index + 1}`

        //* * OrderItem */
        const orderItem = {
          price: this.booking.selectedStorages[index].price,
          discount: this.booking.selectedStorages[index].discount,
          ItemDetails: null,
          rentStart: this.booking.selectedStorages[index].rentStart,
          rentEnd: this.booking.selectedStorages[index].rentEnd
            ? this.booking.selectedStorages[index].rentEnd
            : '2099-12-31T23:00:00.000Z',
          storage: this.booking.selectedStorages[index].id,
          order: null,
          created_by: null,
          updated_by: null,
        }
        console.log(
          '🚀 ~ file: Movein.vue:456 ~ formSubmitted ~ orderItem:',
          orderItem
        )

        //* * Create orderItems */
        this.$store
          .dispatch('addOrderItem', orderItem)
          .then(result => {
            resultOrderItems.push(result)
            console.log(`resultOrderItems ${JSON.stringify(resultOrderItems)}`)
          })
          .catch(error => {
            this.failureNextSteps(error)
          })
      }

      console.log(
        '🚀 ~ file: Movein.vue:472 ~ formSubmitted ~ this.booking:',
        this.booking
      )

      //* * Existing customer */
      if (this.booking.isExistingCustomer) {
        console.log(
          '🚀 ~ file: Movein.vue:480 ~ formSubmitted ~ this.booking.customer:',
          this.booking.isExistingCustomer
        )
        this.spinnerProgressLabel = 'Aktualisiere Kunde...'
        this.$store
          .dispatch('updateCustomer', this.booking.isExistingCustomer)

          .then(result => {
            //* * Order */
            this.createOrder(result, resultOrderItems)

            //* * Create Order */
            // this.createOrderWithOrderItems(resultOrderItems)

            //* * Create Attachments */
            this.createAttachments(result)
          })
          .catch(error => {
            this.failureNextSteps(error, 'updateCustomer')
          })
      } else {
        //* * Create the customer */
        console.log(
          '🚀 ~ file: Movein.vue:499 ~ formSubmitted ~ Create Customer:'
        )
        this.spinnerProgressLabel = 'Erstelle Kunde...'
        this.$store
          .dispatch('addCustomer', this.booking.customer)
          .then(result => {
            console.log(`result ${JSON.stringify(result)}`)
            //* * Order */
            this.createOrder(result, resultOrderItems)

            //* * Create Attachments */
            this.createAttachments(result)

            console.log(result)
          })
          .catch(error => {
            this.failureNextSteps(error, 'addCustomer')
          })
      }
    },

    //* * Upload Attachments */
    createAttachments(result) {
      console.log(
        '🚀 ~ file: Movein.vue:524 ~ createAttachments ~ result:',
        result
      )
      this.spinnerProgressLabel = 'Lade Anlagen hoch...'

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.booking.contractArray.forEach(contractItem => {
        const formData = new FormData()
        formData.append('files', contractItem, 'Vertragsunterlagen.pdf')

        // contractItem.files.forEach(fileItem => {
        //   const file = fileItem
        //   formData.append('files', file)
        // })

        this.$store
          .dispatch('addFile', formData, config)
          .then(respond => {
            console.log(`AddFile Respond: ${JSON.stringify(respond)}`)
            const attachmentWithFiles = {
              note: 'Vertragsunterlagen',
              customer: result.id,
              attachment_type: 2,
              created_by: 'System',
              updated_by: 'System',
              files: respond.map(a => a.id),
            }
            console.log(
              `AttachmentWithFiles: ${JSON.stringify(attachmentWithFiles)}`
            )

            this.$store
              .dispatch('addAttachment', attachmentWithFiles)
              .then(() => {
                // this.$router.go()
              })
          })
          .catch(error => {
            console.log('Error: addFile')
            console.log(error)
            return error
          })
      })

      this.booking.uplAttachment
        .filter(attachmentItem => attachmentItem.files.length > 0)
        .forEach(attachmentItem => {
          // if (attachmentItem.files.length === 0) { return }

          const formData = new FormData()

          attachmentItem.files.forEach(fileItem => {
            const file = fileItem
            formData.append('files', file)
          })

          this.$store
            .dispatch('addFile', formData, config)
            .then(respond => {
              console.log(`AddFile Respond: ${JSON.stringify(respond)}`)
              const attachmentWithFiles = {
                note: attachmentItem.note,
                customer: result.id,
                attachment_type: attachmentItem.id,
                created_by: 'EK',
                updated_by: 'EK',
                files: respond.map(a => a.id),
              }
              console.log(
                `AttachmentWithFiles: ${JSON.stringify(attachmentWithFiles)}`
              )

              this.$store
                .dispatch('addAttachment', attachmentWithFiles)
                .then(() => {
                  // this.$router.go()
                })
            })
            .catch(error => {
              this.failureNextSteps(error, 'add File')
            })
        })

      // update existing attachments on customer with updateAttachment
      this.booking.customer.attachments.forEach(attachmentItem => {
        this.$store
          .dispatch('updateAttachment', attachmentItem)
          .then(() => {
            console.log('Update Attachment')
            // this.$router.go()
          })
          .catch(error => {
            this.failureNextSteps(error, 'updateAttachment')
          })
      })
    },

    createOrder(result, resultOrderItems) {
      console.log(
        '🚀 ~ file: Movein.vue:618 ~ createOrder ~ resultOrderItems:',
        resultOrderItems
      )
      this.booking.order.orderDate = Date.now()
      this.booking.order.order_items = resultOrderItems
      this.booking.order.customer = result.id
      this.booking.order.created_by = 'EK'
      this.booking.order.updated_by = 'EK'

      // Upload CustomerSignature
      //
      if (
        /* eslint-disable operator-linebreak */
        !this.parentRoute
      ) {
        console.log('Upload Customer Signature')

        this.uploadCustomerSignature(resultOrderItems)
      } else {
        console.log('Create Order with OrderItems')
        this.createOrderWithOrderItems(resultOrderItems)
      }
    },

    uploadCustomerSignature(resultOrderItems) {
      console.log(
        '🚀 ~ file: Movein.vue:634 ~ uploadCustomerSignature ~ resultOrderItems:',
        resultOrderItems
      )
      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const formData = new FormData()

      const signatureName = `Signature_${this.booking.customer.lastName}_${this.booking.customer.firstName}.png`

      formData.append(
        'files',
        this.booking.order.customerSignature,
        signatureName
      )
      this.$store
        .dispatch('addFile', formData, config)
        .then(respond => {
          this.booking.order.customerSignature = respond.map(a => a.id)

          this.createOrderWithOrderItems(resultOrderItems)
        })
        .catch(error => {
          this.failureNextSteps(error, 'Upload Signature')
        })
    },

    createOrderWithOrderItems(resultOrderItems) {
      console.log(
        '🚀 ~ file: Movein.vue:669 ~ createOrderWithOrderItems ~ resultOrderItems:',
        resultOrderItems
      )

      this.spinnerProgressLabel = 'Erstelle Buchungseintrag...'

      //* * Create Order */

      console.log(
        '🚀 ~ file: Movein.vue:676 ~ createOrderWithOrderItems ~ this.booking.order:',
        this.booking.order
      )

      this.$store
        .dispatch('addOrder', this.booking.order)
        .then(resultOrder => {
          const orderItems = JSON.parse(JSON.stringify(resultOrderItems))
          console.log(
            '🚀 ~ file: Movein.vue:694 ~ createOrderWithOrderItems ~ orderItems:',
            orderItems
          )
          console.log(
            '🚀 ~ file: Movein.vue:694 ~ createOrderWithOrderItems ~ resultOrder:',
            resultOrder
          )

          this.booking.invoiceNumber = resultOrder.id

          orderItems.forEach(oItem => {
            // eslint-disable-next-line no-param-reassign
            oItem.order = resultOrder.id
          })

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < orderItems.length; index++) {
            //* * Update orderItems */
            this.$store
              .dispatch('updateOrderItem', orderItems[index])
              .catch(error => {
                this.failureNextSteps(error, 'updateOrderItem')
              })
          }

          // TODO: Optimze the Paramaters
          this.successNextSteps(resultOrder, orderItems)
        })
        .catch(error => {
          this.failureNextSteps(error, 'addOrder')
        })
    },
    // eslint-disable-next-line no-unused-vars
    successNextSteps(resultOrder, orderItems) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Buchung erfolgreich erstellt!',
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      // this.generateBookingPDF()
      // this.sendEmail(resultOrder, orderItems)

      if (this.parentRoute !== 'addExistingOrder') {
        console.log('Send Email')
        this.onSendEmail(orderItems, resultOrder)
      }

      this.$bvModal.hide('modal-booking')

      // this.$router.push(`/orders-view/${result.id}`)
      // this.$router.go()
    },
    failureNextSteps(error, description) {
      console.log(`Error: ${description}`)
      console.log(error)
      this.errorcode = error
      this.$bvModal.show('modal-booking-danger')
    },

    async onSendEmail(orderItems, order) {
      const emailType = 'booking'
      console.log(
        '🚀 ~ file: Movein.vue:708 ~ onSendEmail ~ emailType:',
        emailType
      )
      const emailBody = emailStoragesBody(orderItems, emailType)

      const email = {
        to: this.booking.customer.email,
        subject: 'Der Lagermeister',
        text: emailBody,
        attachments: this.booking.contractArray,
        template_id: 'd-8b14bf5786944de1b354018d51e9a397',
        dynamic_template_data: {
          salutation: this.booking.customer.salutation,
          lastName: this.booking.customer.lastName,
          orderDate: this.getFormattedDate(this.booking.orderDate),
          orderID: order.id,
          orderItems: emailBody,
        },
      }

      const result = await sendEmail(email)

      if (result.success) {
        alert(result.message)
      } else {
        alert(result.message)
      }
    },

    sendEmail(orderItems) {
      // (C) CREATE HTML TABLE
      // (C1) HTML TABLE STRING
      // eslint-disable-next-line operator-linebreak
      let myTable =
        '<head><style>table, th, td {border: 0px solid black;border-collapse: collapse;}th {text-align: left;}</style></head><table style="width: 100%;"><tr style="text-align: left;"><th>Name</th><th>ID</th><th>Stadt</th><th>Strasse</th><th>Mietbeginn</th><th>Mietende</th><th>Preis/Monat</th></tr><tr>'
      // (C2) LOOP THROUGH ARRAY & GENERATE ROWS-CELLS
      const perrow = 7 // 2 CELLS PER ROW
      orderItems.forEach((orderItem, i) => {
        // "NORMAL" CELL

        const location = this.$store.getters.locationById(
          Number(orderItem.storage.location)
        )

        myTable += `<td>${orderItem.storage.name}</td>`
        myTable += `<td>${orderItem.storage.storageIdent}</td>`
        myTable += `<td>${location.city}</td>`
        myTable += `<td>${location.street} ${location.streetNumber}</td>`
        myTable += `<td>${this.getFormattedDate(orderItem.rentStart)}</td>`
        myTable += `<td>${this.getFormattedDate(orderItem.rentEnd)}</td>`
        myTable += `<td>${orderItem.price} €</td>`

        // BREAK INTO NEXT ROW
        const next = i + 1
        if (next % perrow === 0 && next !== orderItems) {
          myTable += '</tr><tr>'
        }
      })

      // (C3) CLOSE TABLE
      myTable += '</tr></table>'

      return myTable
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
