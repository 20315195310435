<template>
  <div>
    <!-- ANFRAGE ODER BESTANDSKUNDE -->
    <!--  -->
    <!-- {{ this.whatami }} -->

    <b-row v-if="!parentRoute" class="pb-1">
      <!-- <b-col v-if="inquiry">
        <b-card title="Anfrage">
          <b-form-group label-for="v-inquiry">
            <v-select
              v-model="selectedInquiry"
              :options="inquiriesAcceptedEntries"
              label="email"
              :clearable="true"
              @input="turnInquiryToCustomerForFormFill"
            >
              <template v-slot:option="option">
                {{ option.id }} | {{ getFormattedDate(option.created_at) }} |
                {{ option.firstName }} {{ option.lastName }} |
                {{ option.email }}
              </template>
            </v-select>
          </b-form-group>
        </b-card>
      </b-col> -->
      <b-col>
        <b-card title="Bestandskunde?">
          <BookingCustomerDetailscustomerTableVue
            :customer-entries="customerEntries"
            @customerSelected="existingCustomerFormFill"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Show checkmark "Bestandsbuchung" and if checked set this.üarentRoute zo 'offer' -->
        <b-card>
          <p>
            Bei Bestandskunden dessen Buchung nachträglich erfasst werden muss.
            Es wird keine Benachrichtigung an den Kunden versendet.
          </p>
          <b-form-group label-for="v-existing-customer">
            <b-form-checkbox
              :checked="isChecked"
              @change="handleCheckboxChange"
              name="check-button"
              switch
            >
              Bestandsbuchung?
            </b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <!-- ANFRAGENACHRICHT -->
    <!--  -->
    <b-card v-if="inquiry" title="Anfragenachricht">
      <b-row>
        <b-col>
          <b-form-group label-for="v-message">
            <b-form-textarea v-model="booking.customer.message" rows="3" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Unternehmensinformationen -->
    <!--  -->
    <b-card title="Unternehmensinformationen">
      <b-row class="pb-2">
        <b-col cols="2">
          <b-form-group>
            <b-form-checkbox
              v-model="booking.customer.isOrganization"
              checked="false"
              name="check-button"
              switch
            >
              Gewerblich?
            </b-form-checkbox>
          </b-form-group></b-col
        >

        <b-col sm="12" md="12" lg="12" xl="10">
          <b-form-group>
            <b-form-input
              v-model="booking.customer.organizationName"
              :disabled="booking.customer.isOrganization"
              placeholder="Wayne Enterprise"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Kundeninformationen">
      <b-row class="">
        <b-col sm="12" md="12" lg="6" xl="4">
          <b-form-group label="Anrede" label-for="v-salutation">
            <v-select
              id="v-salutation"
              v-model="booking.customer.salutation"
              :clearable="false"
              :options="getSalutionOptions()"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label="Vorname" label-for="v-firstname">
            <validation-provider
              #default="{ errors }"
              name="Vorname"
              rules="required"
            >
              <b-form-input
                v-model="booking.customer.firstName"
                :state="errors.length > 0 ? false : null"
                placeholder="Bruce"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label="Nachname" label-for="v-lastname">
            <validation-provider
              #default="{ errors }"
              name="Nachname"
              rules="required"
            >
              <b-form-input
                v-model="booking.customer.lastName"
                :state="errors.length > 0 ? false : null"
                placeholder="Wayne"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label="Email" label-for="v-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="booking.customer.email"
                :state="errors.length > 0 ? false : null"
                type="email"
                placeholder="bruce@wayne.de"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6"
          ><b-form-group label="Telefon" label-for="phoneNumber">
            <validation-provider
              #default="{ errors }"
              rules="integer"
              name="Telefon"
            >
              <b-form-input
                v-model="booking.customer.phoneNumber"
                :state="errors.length > 0 ? false : null"
                placeholder="0525112345"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group></b-col
        >
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label="Handy" label-for="mobile">
            <validation-provider
              #default="{ errors }"
              rules="integer"
              name="Handy"
            >
              <b-form-input
                v-model="booking.customer.mobileNumber"
                :state="errors.length > 0 ? false : null"
                placeholder="01701231231"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label="Fax" label-for="fax">
            <validation-provider
              #default="{ errors }"
              rules="integer"
              name="Fax"
            >
              <b-form-input
                v-model="booking.customer.faxNumber"
                :state="errors.length > 0 ? false : null"
                placeholder="0525198765"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Adresse -->
    <!--  -->
    <b-card title="Adresse">
      <b-row class="">
        <b-col sm="9" md="9" lg="10" xl="6">
          <b-form-group label="Strasse" label-for="">
            <validation-provider
              #default="{ errors }"
              name="Strasse"
              rules="required"
            >
              <b-form-input
                v-model="booking.customer.street"
                :state="errors.length > 0 ? false : null"
                placeholder="Waynestrasse"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="3" md="3" lg="2" xl="2">
          <b-form-group label="Hausnummer" label-for="">
            <validation-provider
              #default="{ errors }"
              name="Hausnummer"
              rules="required"
            >
              <b-form-input
                v-model="booking.customer.streetNumber"
                :state="errors.length > 0 ? false : null"
                placeholder="1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col sm="3" md="3" lg="2" xl="2">
          <b-form-group label="PLZ" label-for="">
            <validation-provider
              #default="{ errors }"
              name="PLZ"
              rules="required|digits:5"
            >
              <b-form-input
                v-model="booking.customer.zipCode"
                :state="errors.length > 0 ? false : null"
                placeholder="33104"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="9" md="9" lg="10" xl="6">
          <b-form-group label="Stadt" label-for="">
            <validation-provider
              #default="{ errors }"
              name="Stadt"
              rules="required"
            >
              <b-form-input
                v-model="booking.customer.city"
                :state="errors.length > 0 ? false : null"
                placeholder="Paderborn"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="9" md="9" lg="6" xl="6">
          <b-form-group label="Land" label-for="">
            <validation-provider
              #default="{ errors }"
              name="Land"
              rules="required"
            >
              <v-select
                id="v-country"
                v-model="booking.customer.country"
                :clearable="false"
                :options="getCountryOptions()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import helper from '@/helper/helper'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BCard,
  BCardText,
  BFormTextarea,
  BButton,
  BTable,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import tableHelper from '../Table+Helper'
import BookingCustomerDetailscustomerTableVue from './BookingCustomerDetailscustomerTable.vue'

export default {
  setup() {
    const { getFormattedDate, getCountryOptions, getSalutionOptions } = helper()
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      getFormattedDate,
      getCountryOptions,
      getSalutionOptions,
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BCard,
    BCardText,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BTable,
    BBadge,
    BPagination,
    vSelect,
    BookingCustomerDetailscustomerTableVue,
  },
  props: ['booking', 'inquiry', 'parentRoute'],
  data() {
    return {
      whatami: '',
      isChecked: false,
    }
  },
  computed: {
    customerEntries() {
      return this.$store.getters.customerEntries
    },
    inquiriesAcceptedEntries() {
      return this.$store.getters.inquiriesAcceptedEntries
    },
  },
  mounted() {
    if (this.inquiry) {
      this.whatami = 'inquiry'
    } else {
      this.whatami = 'booking'
    }
  },
  methods: {
    handleCheckboxChange(checked) {
      // Setzt 'parentRoute' auf 'offer', wenn das Kontrollkästchen aktiviert ist, und lässt es leer, wenn es deaktiviert ist
      this.$emit('updateParentRoute', checked ? 'addExistingOrder' : '')
    },

    existingCustomerFormFill(value, isInquiry) {
      this.booking.isExistingCustomer = !isInquiry

      if (value === null) {
        // eslint-disable-next-line no-restricted-syntax
        this.booking.customer = {}
      } else {
        this.booking.customer = JSON.parse(JSON.stringify(value))

        if (isInquiry) {
          this.booking.customer.billingAddressDiffers = false
        }
      }
    },

    turnInquiryToCustomerForFormFill(value) {
      this.existingCustomerFormFill(value, true)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}
</style>
